<script>
export default {
  name: 'IconTextTag',
  props: {
    text: {
      type: [String, Number]
    },
    icon: {
      type: String
    },
    size: {
      type: String,
      default: '24px'
    },
    color: {
      type: String,
      default: '#7E7D77'
    },
    bold: {
      type: Boolean,
      default: false
    }
  }
}
</script>
<template>
  <div class="icon-text-tag--container">
    <v-icon :size="size" :color="color">{{ icon }}</v-icon>
    <span class="body--2" :style="{'color': color, 'font-weight': bold ? 'bold' : 'normal'}">{{ $t(text) }}</span>
  </div>
</template>
<style lang="scss">
.icon-text-tag--container {
  display: flex;
  margin-bottom: 8px;
  .v-icon {
    margin-right: 4px;
  }
}
</style>
