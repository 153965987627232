<script>
import ModalPositionDetails from '../positions/ModalPositionDetails.vue'
import ModalContentCourseDetails from '../contents/ModalContentCourseDetails.vue'
import IconTextTag from '@/components/general/IconTextTag.vue'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'SearchResultCard',
  components: {
    IconTextTag,
    ModalPositionDetails,
    ModalContentCourseDetails
  },
  props: {
    items: {
      type: Object,
      required
    },
    type: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      showModal: false,
      show: false,
      item: { ...this.items }
    }
  },
  methods: {
    toggleFavorite () {
      const isFavorite = this.item.isFavorite
      this.item.isFavorite = !isFavorite
      if (this.type === 'courses') {
        this.$store.dispatch(isFavorite ? 'attemptRemoveFavoriteCourse' : 'attemptSaveFavoriteCourse', { course_id: this.item.id })
          .catch((err) => {
            if (err.code === 403 && err.error === 'not_allowed') {
              this.showNotAllowedModal = true
            } else {
              this.directToRoute()
              this.$store.dispatch('attemptSetFeedbackMsg', {
                type: 'error',
                title: this.$t('content:error.load')
              })
            }
          }).finally(() => { this.loading = false })
      } else if (this.type === 'positions') {
        this.$store.dispatch(isFavorite ? 'attemptRemoveFavoritePosition' : 'attemptSaveFavoritePosition', this.item.id)
          .catch((err) => {
            if (err.code === 403 && err.error === 'not_allowed') {
              this.showNotAllowedModal = true
            } else {
              this.directToRoute()
              this.$store.dispatch('attemptSetFeedbackMsg', {
                type: 'error',
                title: this.$t('content:error.load')
              })
            }
          }).finally(() => { this.loading = false })
      }
    },
    handleFavorite (isFavorite) {
      this.item.isFavorite = isFavorite
    },
    handleShareOption (item) {
      if (!item.url && !item.positionUrl && !item.courseUrl) {
        this.$store.dispatch('attemptSetFeedbackMsg', {
          type: 'error',
          title: this.$t('content:error.share')
        })
      } else {
        const url = this.type === 'positions' ? item.positionUrl : this.fixedCourseUrl(item.url || item.courseUrl)
        navigator.clipboard.writeText(url)
        this.$store.dispatch('attemptSetFeedbackMsg', {
          type: 'success',
          title: this.$t('content:success.share')
        })
      }
    },
    getAvatar () {
      if (this.item.company === undefined) {
        return 'https://assets.plooral.dev/thumbnails/avatar/workspaces/image/1640611483899-blob'
      } else {
        if (this.type === 'positions') return this.item.company.logo.includes('empty') ? 'https://assets.plooral.dev/thumbnails/avatar/workspaces/image/1640611483899-blob' : Object.prototype.hasOwnProperty.call(this.item.company, 'logo') ? this.item.company.logo : 'https://assets.plooral.dev/thumbnails/avatar/workspaces/image/1640611483899-blob'
        if (this.type === 'courses') return this.item.image ? '/assets/images/breadcrumbs.png' : this.item.image
      }
    }
  },
  watch: {
    items: {
      handler (val) {
        this.show = false
        setTimeout(() => {
          this.item = { ...val }
          this.show = true
        }, 1000)
      },
      deep: true,
      immediate: true
    }
  }
}
</script>
<template>
  <div class="search-result-card--container" v-if="(!!this.item.company || this.type === 'courses') && show">
    <ModalContentCourseDetails
        v-if="type === 'courses' && showModal"
        @close="showModal = !showModal"
        :id="item.id"
        :placeholder="item"
        :onFavorite="handleFavorite"
      />
    <ModalPositionDetails
      v-if="type === 'positions' && showModal"
      @close="showModal = !showModal"
      :id="item.id"
      :placeholder="item"
      :onFavorite="handleFavorite"
    />
    <div class="search-result-card--upper" >
      <h3 class="text-left">{{ item.title }}</h3>
      <div class="search-result-card--share-actions">
        <v-chip v-if="item.disabledOnly">
          <v-icon small color="#2196F3">mdi-human</v-icon>
          <p class="ml-1 data-text-chip">{{ $t('discovery.list.search:accessibility') }}</p>
        </v-chip>
        <v-icon size="24px" class="cursor-pointer" :color="type === 'courses' ? getAccentColor : getPrimaryColor" @click="handleShareOption(item)">mdi-share-variant</v-icon>
        <v-icon size="24px" class="cursor-pointer" :color="type === 'courses' ? getAccentColor : getPrimaryColor" @click="toggleFavorite()">{{ item.isFavorite ? 'mdi-heart' : 'mdi-heart-outline' }}</v-icon>
      </div>
    </div>
    <div class="search-result-card--middle">
      <div class="search-result-card--owner-info">
        <v-avatar :color="type === 'courses' ? '#FFF' : getPrimaryColor" size="22">
          <img class="avatar--icon" :src="type === 'courses' ? item.provider.logo : getAvatar()" height="22" width="22"/>
        </v-avatar>
        <p>{{ type === 'courses' ? item.provider.name : item.company === undefined ? '' : item.company.name }}</p>
      </div>
    </div>
    <div class="search-result-card--lower d-flex flex-row">
      <div class="search-result-card--info">
        <icon-text-tag
          class="search-result-card--icon-text"
          :text="type === 'courses' ? $t('content.type:course') : $t('application.card:position')"
          :icon="type === 'courses' ? 'mdi-school' : 'mdi-briefcase'"
          :color="type === 'courses' ? getAccentColor : getPrimaryColor"
          size="20px"
          bold
        />
        <icon-text-tag
          class="search-result-card--icon-text"
          :text="item.modality"
          icon="mdi-map-marker"
          size="20px"
          color="#7E7D77"
          v-if="type === 'courses'"
        />
        <icon-text-tag
          class="search-result-card--icon-text"
          :text="item.telecommute ? $t('discovery.details.label:details.remote') : item.location"
          icon="mdi-map-marker"
          size="20px"
          color="#7E7D77"
          v-else
        />
        <icon-text-tag
          class="search-result-card--icon-text"
          :text="item.workload > 1? `${item.workload} ${$t('global:hours.plural')}` : `${item.workload} ${$t('global:hour.singular')}`"
          icon="mdi-briefcase-clock"
          size="20px"
          color="#7E7D77"
          v-if="type === 'courses' && item.workload"
        />
        <icon-text-tag
          class="search-result-card--icon-text"
          :text="'discovery.modal.search:contract.type.' + item.type"
          icon="mdi-briefcase-clock"
          size="20px"
          color="#7E7D77"
          v-if="type !== 'courses'"
        />
        <icon-text-tag
          class="search-result-card--icon-text"
          :text="$t(item.price ? 'discovery.modal.search:pricing.paid' : 'discovery.modal.search:pricing.free')"
          icon="mdi-currency-usd"
          size="20px"
          color="#7E7D77"
          v-if="type === 'courses' && item.price !== null"
        />
        <icon-text-tag
          class="search-result-card--icon-text"
          :text="formatSalary(item)"
          icon="mdi-currency-usd"
          size="20px"
          color="#7E7D77"
          v-if="type !== 'courses'"
        />
      </div>
      <div class="search-result-card--info-action">
        <v-btn
          text
          class="bold"
          :color="type === 'courses' ? getAccentColor : getPrimaryColor"
          @click.prevent="showModal = !showModal"
          :href="`${getWorkspaceRoot()}/discovery/${type === 'courses' ? 'course' : 'position'}/${item.id}`"
        >
          {{ $t('global:show.more') }}
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
  .search-result-card--container {
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.06);
    border-radius: 4px;
    margin-bottom: 32px;
    & > div {
      display: block;
      width: 100%;
    }
    .search-result-card--upper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 24px 24px 0 24px;
      margin-bottom: 16px;
      h3 {
        font-family: 'Lato';
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        text-transform: capitalize;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        flex: 1;
      }
      .search-result-card--share-actions {
        white-space: nowrap;
        .v-icon {
          margin-left: 8px;
        }
        .v-chip {
          padding-left: 5px;
          background: linear-gradient(0deg, rgba(33, 150, 243, 0.1), rgba(33, 150, 243, 0.1)), #FFFFFF;
          height: 25px;
          p.data-text-chip{
            margin: 0;
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 18px;
            color: #2196F3;
          }
        }
      }
    }
    .search-result-card--middle {
      text-align: left;
      padding: 0 24px;
      margin-bottom: 24px;
      .search-result-card--owner-info {
        display: flex;
        p {
          margin: 0 0 0 8px;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
          color: #7E7D77;
        }
      }
    }
    .search-result-card--lower {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 8px 24px;
      background: #FAFAFA;
      border-top: 1px solid rgba(0, 0, 0, 0.06);
      .search-result-card--info {
        display: flex;
        .search-result-card--icon-text {
          text-transform: capitalize;
        }
        > * {
          margin-right: 12px;
        }
      }
      .search-result-card--info-action {
        .v-btn--text {
          padding-right: 16px;
        }
      }
    }
    @media only screen and (max-width: 800px) {
      .search-result-card--lower {
        flex-direction: column;
        .search-result-card--info {
          flex-direction: column;
        }
      }
      .search-result-card--share-actions {
        .v-chip {
          display: none;
        }
      }
    }
  }
</style>
